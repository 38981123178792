// 涉外代理
<template>
  <body>
    <el-card>
      <div class="list-block">
        <div class="card-block">
          <el-checkbox-group
            style="flex-wrap: wrap"
            v-model="checked"
            @change="handleCheckedChange"
          >
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="(item, index) in tableData" :key="index" class="card">
                <el-checkbox
                  :label="item.agentid"
                  class="checkbox"
                  style="position: absolute; top: 4px; left: 6px"
                  >&nbsp;</el-checkbox
                >
                <div @click="openDetail(item)">
                  <span class="text-title" :title="item.title">{{
                    item.title
                  }}</span>
                  <div class="pic-contain">
                    <img :src="item.picurl" height="210" />
                  </div>
                  <span class="text-detail" :title="item.introduction">{{
                    item.introduction
                  }}</span>
                  <!-- <el-button
                @click="toEdit(item.name)"
                type="text"
                size="middle"
                style="font-size: 15px; margin-top: 25%; margin-left: 85%"
                >编辑</el-button
              > -->
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <el-checkbox
        class="checkall"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >
        全选
      </el-checkbox>
      <el-button
        class="deletebutton"
        type="danger"
        :disabled="checked.length === 0"
        @click="deleteTem(deleteall)"
        >删除</el-button
      >
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>
    <!-- 新建/修改涉外代理 -->
    <el-dialog title="涉外代理" v-model="foreVisible" width="55%">
      <el-form ref="gloryScope" :model="foreForm">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 70%" v-model="foreForm.title" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="图片" prop="picurl">
          <el-upload
            id="uploadImage"
            name="file"
            :action="`${fileUploadURL}files/upload`"
            :on-change="handleChange"
            :handle-remove="handleRemove"
            :on-remove="onRemove"
            :on-success="imageHandleSuccess"
            :file-list="imageFile"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
          <span class="tiptext" v-show="imageFile === ''">未上传文件 </span>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input
            style="width: 70%"
            type="textarea"
            :rows="4"
            v-model="foreForm.introduction"
            clearable
          >
          </el-input>
        </el-form-item>
        <el-form-item label="详情" prop="content">
          <TEditor3
            ref="editor"
            style="width: 80%; height: 500px"
            :auto-height="true"
            v-model="foreForm.content"
            @update="updateData($event)"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="foreVisible = false">取 消</el-button>
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
import { uploadToAli } from "@/utils/alioss.js";
import TEditor3 from "@/components/Tinymce/Tinymce.vue";
import axios from "axios";
export default {
  components: { TEditor3 },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      foreVisible: false,
      foreId: "",
      fileList: [],
      foreForm: {
        title: "",
        picname: "",
        picurl: "",
        content: "",
        introduction: "", //简介
      },
      isIndeterminate: null,
      checkAll: false,
      checked: [],
      deleteall: "all", //全选后删除
      showModuleName: false,
      fileUploadURL: axios.defaults.baseURL,
      resImageFileName: [],
      imageFile: []
    };
  },
  //mounted是刷新的时候执行的
  mounted() {
    this.getTableData();
  },
  methods: {
    async deleteTem(item) {
      let ids = [];
      if (item === "all") {
        ids = this.checked;
      } else {
        ids = [item.agentid];
      }
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/agents/DeletAgent",
          method: "delete",
          data: {
            ids: ids,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.$message.success("删除成功");
            this.checked = [];
            this.getTableData();
            this.checkAll = false;
            this.isIndeterminate = null;
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },

    // 点击多选框
    handleCheckedChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.tableData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length;
    },
    // 勾选全选
    handleCheckAllChange() {
      this.checked = [];
      if (this.checkAll) {
        this.tableData.forEach((item) => {
          this.checked.push(item.agentid);
        });
      }
      this.isIndeterminate = false;
    },
    updateData(e) {
      this.foreForm.content = e;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    //上传文件
    handleChange(file, fileList) {
      // console.log("这是file", file);
      // console.log("这是fileList", fileList);
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.imageFile = fileList
    },
    //上传文件
    async toUploadfile(file) {
      this.fileList = [];
      var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // this.uploadUrl = uploadUrl
      await uploadToAli(storeAs, file.file);
      const fileObj = {
        name: file.file.name,
        url: uploadUrl,
      };
      this.fileList.push(fileObj);
    },
    //删除文件
    handleRemove(file) {
      this.resImageFileName = []
      this.imageFile = []
      // if (file && file.status === "success") {
      //   this.$axios.delete("accessory/one/" + file.response.id).then((resp) => {
      //     if (resp.status === 200) {
      //       this.$message({
      //         message: "删除成功",
      //         type: "success",
      //       });
      //     }
      //   });
      // }
    },
    //删除文件
    onRemove(file, fileList) {
      this.fileList = [];
      this.resImageFileName = []
      this.imageFile = []
    },
    imageHandleSuccess(response, file,	fileList){
      let imageNewFile = {
        rawFileName : file.name,
        newFileName : response.data,
      }
      this.resImageFileName.shift();
      this.resImageFileName.push(imageNewFile)
    },
    //添加新闻
    addbutton() {
      this.foreId = "";
      (this.imageFile = []),
        (this.foreForm = {
          title: "",
          picname: "",
          picurl: "",
          content: "",
          introduction: "", //简介
        });
      this.foreVisible = true;
    },
    openDetail(item) {
      this.foreId = item.agentid;
      this.imageFile = [];
      const fileObj = {
        name: item.picname,
        url: item.picurl,
      };
      this.imageFile.push(fileObj);
      this.foreForm = {
        title: item.title,
        picname: item.picname,
        picurl: item.picurl,
        content: item.content,
        introduction: item.introduction,
      };
      this.foreVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.foreId === "") {
        const response = await this.$axios({
          method: "post",
          url: "/agents/InputAgent",
          data: {
            title: this.foreForm.title,
            picname: this.resImageFileName[0].rawFileName,
            picurl: axios.defaults.baseURL + "files/download/" + this.resImageFileName[0].newFileName,
            introduction: this.foreForm.introduction,
            content: this.foreForm.content,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.foreVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/agents/PutAgent",
          data: {
            agentid: this.foreId,
            title: this.foreForm.title,
            picname: this.resImageFileName[0].rawFileName,
            picurl: axios.defaults.baseURL + "files/download/" + this.resImageFileName[0].newFileName,
            introduction: this.foreForm.introduction,
            content: this.foreForm.content,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.foreVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    async getTableData() {
      await this.$axios
        .get("/agents/GetAgentAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.list-block {
  width: 97.4%;
  /* height: 805px; */
  margin-left: 1.5%;
}
.card-block {
  overflow: auto;
  height: 95%;
}
.text-title {
  display: inline-block;
  width: 95%;
  color: #303133;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 8%;
}
.text-detail {
  width: 95%;
  color: #303133;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /*数字代表文字需要显示几行*/
  word-break: break-all;
  margin-left: 2.5%;
  text-align: justify;
}
.pic-contain {
  width: 100%;
  /* height: 50%; */
  background-color: rgb(229, 245, 252);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card {
  width: 320px;
  cursor: pointer;
  height: 320px;
  /* background-color: #fff; */
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-left: 1.8%;
  margin-bottom: 1%;
  position: relative;
  overflow: hidden;
}
.addbutton {
  margin-left: 65%;
  position: absolute;
}
.deletebutton {
  margin-left: 58%;
  position: absolute;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
  margin-left: 50px;
}
.checkbox /deep/ .el-checkbox__input .el-checkbox__inner {
  border: 1px solid #7f8490;
}
.checkall /deep/ .el-checkbox__input .el-checkbox__inner {
  border: 1px solid #7f8490;
}
.el-checkbox-group {
  font-size: 15px;
}
</style>