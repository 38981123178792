// 执行董事
<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-form
          :model="execForm"
          label-width="150px"
          class="form"
          style="margin-top: 50px"
        >
          <el-form-item label="姓名" prop="director">
            <el-input
              style="width: 540px"
              v-model="execForm.director"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片" prop="picurl">
            <el-upload
              id="uploadImage"
              name="file"
              :action="`${fileUploadURL}files/upload`"
              :handle-remove="handleRemove"
              :on-remove="onRemove"
              :on-change="handleChange"
              :file-list="imageFile"
              :on-success="imageHandleSuccess"
              :auto-upload="true"
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
            <span class="tiptext" v-show="imageFile === ''">未上传文件 </span>
          </el-form-item>
          <el-form-item label="详情" prop="name">
            <TEditor7
              ref="editor"
              style="width: 600px; height: 500px"
              :auto-height="true"
              v-model="execForm.introduction"
              @update="updateData($event)"
              width="100%"
            />
          </el-form-item>
          <el-button class="addbutton" type="primary" @click="addbutton"
            >确定</el-button
          >
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { uploadToAli } from "@/utils/alioss.js";
import TEditor7 from "@/components/Tinymce/Tinymce.vue";
import axios from "axios";
export default {
  components: { TEditor7 },
  name: "upload",
  data() {
    return {
      fileUploadURL: axios.defaults.baseURL,
      id: "",
      fileList: [],
      execForm: {},
      resImageFileName: [],
      imageFile: []
    };
  },
  mounted() {
    this.getTableDataEdit();
  },
  activated() {
    this.getTableDataEdit();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    updateData(e) {
      this.execForm.introduction = e;
    },
    addbutton() {
      this.putTableData();
    },
    imageHandleSuccess(response, file,	fileList){
      let imageNewFile = {
        rawFileName : file.name,
        newFileName : response.data,
      }
      this.resImageFileName.shift();
      this.resImageFileName.push(imageNewFile)
    },
    handleChange(file, fileList) {
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.imageFile = fileList
    },
    //上传文件
    async toUploadfile(file) {
      // this.fileList = [];
      // var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      // var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // var uploadUrl = axios.defaults.baseURL + storeAs;
      // var uploadUrl = axios.defaults.baseURL + "files/download/" + new Date().getTime() + "&" + file.file.name; // 添加 url 属性
      // this.uploadUrl = uploadUrl
      // await uploadToAli(storeAs, file.file);

      if (response.data.status === "OK") {
        this.$message.success("添加成功");
        this.getTableData();
        scrollBy(0, -top);
        this.conversionVisible = false;
      } else {
        this.$message.error("添加失败");
      }
      // const fileObj = {
      //   name: file.file.name,
      //   url: uploadUrl,
      // };
      // this.fileList.push(fileObj);
    },
    handleRemove(file) {
      this.resImageFileName = []
      this.imageFile = []
      // if (file && file.status === "success") {
      //   this.$axios.delete("accessory/one/" + file.response.id).then((resp) => {
      //     if (resp.status === 200) {
      //       this.$message({
      //         message: "删除成功",
      //         type: "success",
      //       });
      //     }
      //   });
      // }
    },
    //删除文件
    onRemove(file, fileList) {
      this.fileList = [];
      this.resImageFileName = []
      this.imageFile = []
    },
    async getTableDataEdit() {
      await this.$axios
        .get("/directors/GetDirector", {})
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.execForm = { ...response.data };
          this.id = response.data.directorid;
          if (this.execForm.picname !== "") {
            this.imageFile = [];
            const fileObj = {
              name: this.execForm.picname,
              url: this.execForm.picurl,
            };
            this.imageFile.push(fileObj);
          }
        });
    },
    //将填报的数据发给后端
    async putTableData() {
      console.log(this.imageFile)
      console.log(this.resImageFileName)
      if(!this.imageFile || (Array.isArray(this.imageFile) && this.imageFile.length === 0)){
        this.$message.error('图片不能为空。');
        return;
      }
      var urlList = []
      var fname = ""
      this.imageFile.forEach((file,index) => {
        file.url = axios.defaults.baseURL + "files/download/" + this.resImageFileName[index].newFileName; // 添加 url 属性
        file.fsite = '1';
        file.name = this.resImageFileName[index].newFileName;
        urlList.push(file.url);
        fname = this.resImageFileName[index].newFileName;
      });
      // console.log(urlList)
      // console.log(fname)
      // // file添加到数据库
      // try {
      //   const response = await this.$axios({
      //     method: "post",
      //     url: "/files/dbsave",
      //     params: {
      //       contId: -1,
      //       fileSite: '1',
      //       fname: fname,
      //     },
      //     data: urlList,  // 确保直接发送数组
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   });
      //   console.log(response.data); // 处理响应数据
      // } catch (error) {
      //   console.error('Error:', error.response ? error.response.data : error.message); // 打印错误信息
      // }
      // 代理人添加到数据库
      const response = await this.$axios({
        method: "put",
        url: "/directors/PutDirector",
        data: {
          directorid: this.id,
          director: this.execForm.director,
          picname: this.resImageFileName[0].rawFileName,
          picurl: this.imageFile[0].url,
          introduction: this.execForm.introduction,
        },
        dataType: "json",
      });
      if (response.data.status === "OK") {
        this.$message.success("修改成功");
        this.getTableDataEdit();
      } else {
        this.$message.error("修改失败");
      }
    },
  },
};
</script>

<style scoped>
.el-form .el-form-item /deep/ .el-form-item__label {
  width: 200px !important;
  font-size: 18px;
}
.addbutton {
  margin-top: -10px;
  margin-left: 85%;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
  margin-left: 50px;
}
</style>