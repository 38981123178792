// 高价值专利转化
<template>
  <body>
  <el-card>
    <el-table :data="tableData" style="width: 100%" class="box-table">
      <el-table-column prop="wtime" label="时间" width="100px">
      </el-table-column>
      <el-table-column prop="cname" label="标题"> </el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scope">
          <el-button
              @click="deleteRow(scope.row.cid)"
              type="text"
              size="middle"
              style="font-size: 15px"
          >删除</el-button>
          <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
          >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button class="addbutton" type="primary" @click="addbutton"
    >添 加</el-button
    >
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
    >
      <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
      >GO</el-button
      >
    </el-pagination>
  </el-card>

  <!-- 添加专利/修改专利 -->
  <el-dialog title="高价值专利转化" v-model="conversionVisible" width="55%">
    <el-form :label-position="labelPosition" label-width="80px" ref="newScope" :model="conversionForm" style="margin-left: 30px">
      <el-form-item label="专利名称" prop="cname">
        <el-input placeholder="请输入专利名称" style="width: 80%;" v-model="conversionForm.cname" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="申请号" prop="cname">
        <el-input placeholder="请输入申请号" style="width: 80%;" v-model="conversionForm.patentnum" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="申请日期" prop="cname">
        <el-input placeholder="请输入申请日期" style="width: 80%;" v-model="conversionForm.appdate" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="申请人" prop="cname">
        <el-input placeholder="请输入申请人" style="width: 80%;" v-model="conversionForm.applicant" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="专利内容" prop="content">
        <el-input
            style="width: 80%;"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入专利内容"
            v-model="conversionForm.content">
        </el-input>
      </el-form-item>
      <el-form-item label="上传图片" prop="files">
        <el-upload :action="`${fileUploadURL}files/upload`"
                   ref="upload"
                   :show-file-list="true"
                   accept=".png, .jpg, .jpeg"
                   :on-remove="imageHandleRemove"
                   :before-remove="beforeRemove"
                   :on-change="imageHandleChange"
                   :before-upload="beforeUpload"
                   :on-success="imageHandleSuccess"
                   :file-list="imageFile"
                   :auto-upload="true">
          <el-button type="primary"  slot="trigger" style="float:left;">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传视频" prop="files">
        <el-upload :action="`${fileUploadURL}files/upload`"
                   ref="upload"
                   :show-file-list="true"
                   accept=".mp4, .avi, .mov, .wmv, .mkv"
                   :on-remove="vedioHandleRemove"
                   :before-remove="beforeRemove"
                   :on-change="vedioHandleChange"
                   :before-upload="beforeUpload"
                   :on-success="vedioHandleSuccess"
                   :file-list="vedioFile"
                   :auto-upload="true">
          <el-button type="primary"  slot="trigger" style="float:left;">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传附件" prop="files">
        <el-upload :action="`${fileUploadURL}files/upload`"
                   ref="upload"
                   :show-file-list="true"
                   accept=".pdf"
                   :on-remove="attachHandleRemove"
                   :before-remove="beforeRemove"
                   :on-exceed="attachHandleExceed"
                   :on-change="attachHandleChange"
                   :on-success="attachHandleSuccess"
                   :before-upload="beforeUpload"
                   :limit="3"
                   :file-list="attachFiles"
                   :auto-upload="true">
          <el-button type="primary"  slot="trigger" style="float:left;">点击上传</el-button>
          <div style="clear:both;"></div>
          <div class="el-upload__tip" style="font-size: 10px;" slot="tip">pdf类型的文件可以上传,大小不能超过200MB,且不超过3个文件。</div>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer" style="margin-right: 40px">
          <el-button size="small" @click="conversionVisible = false">取 消</el-button>
          <el-button @click="toSureAddScope" size="small" type="primary">确 定</el-button>
        </span>
    </template>
  </el-dialog>
  </body>
</template>
<script>

import myWangEditor from "@/components/myWangEditor";
import axios from "axios";

export default {
  components: { myWangEditor,axios},
  data() {
    return {
      fileUploadURL:axios.defaults.baseURL,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [], //存放table数据
      conversionForm: {
        cname: "",
        content: "",
        files: [],
        applicant: "",
        appdate: "",
        patentnum: ""
      },
      wangeditorFiles: [],
      conversionVisible: false,
      cid: "",
      files: [],
      option: "",
      loadEdit: null,
      imageFile:[], //图片文件
      vedioFile:[], //视频文件
      attachFiles:[], //附件
      resImageFileName: [],
      resVedioFileName: [],
      resFileName: [],
      labelPosition: "right",
    };
  },
  created() {

  },
  mounted() {
    this.getTableData();
  },
  watch: {
  },
  methods: {
    // 所有的可以共用
    beforeUpload(file) {
      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 200) {
        this.$msgbox({
          title: "",
          message: "文件大小不能超过200MB，请重新上传。",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    // 可共用
    beforeRemove(file) {
      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize < 10){
        return this.$confirm(`确定移除 ${ file.name }？`);
      }
    },
    // 图像上传后返回的数据
    imageHandleSuccess(response, file,	fileList){
      let imageNewFile = {
        rawFileName : file.name,
        newFileName : response.data,
      }
      this.resImageFileName.shift();
      this.resImageFileName.push(imageNewFile)
    },
    imageHandleChange(file,fileList){
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.imageFile = fileList
    },
    imageHandleRemove(file, fileList) {
      this.resImageFileName = []
      this.imageFile = []
    },
    vedioHandleSuccess(response, file,	fileList){
      let vedioNewFile = {
        rawFileName : file.name,
        newFileName : response.data,
      }
      this.resVedioFileName.shift();
      this.resVedioFileName.push(vedioNewFile)
    },
    vedioHandleChange(file,fileList){
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      this.vedioFile = fileList
    },
    vedioHandleRemove(file, fileList) {
      this.resVedioFileName = []
      this.vedioFile = []
    },
    attachHandleChange(file,fileList){
      this.attachFiles = fileList
    },
    attachHandleSuccess(response, file,	fileList) {
      let newFile = {
        rawFileName : file.name,
        newFileName : response.data,
      }
      this.resFileName.push(newFile)
    },
    attachHandleRemove(file, fileList) {
      // 移除后，需要判断，移除的是哪一个，之后将对应位置的resFileName删除
      const indexToRemove = this.resFileName.findIndex(resFile => resFile.rawFileName === file.name);
      // 如果找到，删除该元素
      if (indexToRemove !== -1) {
        this.resFileName.splice(indexToRemove, 1); // 从数组中删除对应的元素
      }
      this.attachFiles = fileList;
    },
    attachHandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    // 分页查询
    async getTableData() {
      await this.$axios
          .get("/conversion/GetConversionAll", {
            params: {
              pagesize: this.pageSize,
              currentpage: this.currentPage,
            },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.tableData = response.data.data;
              this.total = response.data.total;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    // 添加转化
    addbutton() {
      this.cid = "";
      this.conversionForm = {
        cid:"",
        cname: "",
        content: null,
        files: [],
      };
      this.resFileName = []
      this.resImageFileName = []
      this.resVedioFileName = []
      this.imageFile = []
      this.vedioFile = []
      this.attachFiles = []
      this.conversionVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      // console.log(this.resImageFileName)
      if (!this.conversionForm.cname) {
        this.$message.error('专利名称不能为空。');
        return;
      }
      if(!this.conversionForm.patentnum){
        this.$message.error('申请号不能为空。');
        return;
      }
      if(!this.conversionForm.appdate){
        this.$message.error('申请日期不能为空。');
        return;
      }
      if(!this.conversionForm.applicant){
        this.$message.error('申请人不能为空。');
        return;
      }
      if(!this.conversionForm.content){
        this.$message.error('申请内容不能为空。');
        return;
      }
      // console.log(this.imageFile)
      if(!this.imageFile || (Array.isArray(this.imageFile) && this.imageFile.length === 0)){
        this.$message.error('图片不能为空。');
        return;
      }
      // console.log(this.vedioFile)
      if(!this.vedioFile || (Array.isArray(this.vedioFile) && this.vedioFile.length === 0)){
        this.$message.error('视频不能为空。');
        return;
      }
      this.imageFile.forEach((file,index) => {
        file.url = axios.defaults.baseURL + "files/download/" + this.resImageFileName[index].newFileName; // 添加 url 属性
        // console.log("test")
        // console.log(this.resImageFileName[index].newFileName)
        file.fsite = '1';
        file.name = this.resImageFileName[index].newFileName;
      });
      this.vedioFile.forEach((file,index) => {
        file.url = axios.defaults.baseURL + "files/download/" + this.resVedioFileName[index].newFileName; // 添加 url 属性
        file.fsite = '2';
        file.name = this.resVedioFileName[index].newFileName;
      });
      this.attachFiles.forEach((file,index) => {
        file.url = axios.defaults.baseURL + "files/download/" + this.resFileName[index].newFileName; // 添加 url 属性
        file.fsite = '3';
        file.name = this.resFileName[index].newFileName;
      });

      if (this.cid === "") {
        const response = await this.$axios({
          method: "post",
          url: "/conversion/InputConversion",
          data: {
            cname: this.conversionForm.cname,
            content: this.conversionForm.content,
            patentnum: this.conversionForm.patentnum,
            appdate: this.conversionForm.appdate,
            applicant: this.conversionForm.applicant,
            files: [...this.attachFiles, ...this.imageFile, ...this.vedioFile],
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.conversionVisible = false;
        } else {
          this.$message.error("添加失败");
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/conversion/PutConversion",
          data: {
            cid: this.cid,
            cname: this.conversionForm.cname,
            content: this.conversionForm.content,
            patentnum: this.conversionForm.patentnum,
            appdate: this.conversionForm.appdate,
            applicant: this.conversionForm.applicant,
            files: [...this.attachFiles, ...this.imageFile, ...this.vedioFile],
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.conversionVisible = false;
        } else {
          this.$message.error("修改失败");
        }
      }
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      // console.log(data)
      this.cid = data.cid;
      this.resFileName = []
      this.resImageFileName = []
      this.resVedioFileName = []
      this.vedioFile = []
      this.imageFile = []
      this.attachFiles = []
      data.files.forEach((file) => {
        file.name = file.fname.split('_')[1]; // 添加 name 属性
        let newFile = {
          rawFileName : file.fname.split('_')[1],
          newFileName : file.fname,
        }
        if(file.fsite === "1"){
          this.resImageFileName.push(newFile)
          this.imageFile.push(file)
        }else if(file.fsite==="2"){
          this.resVedioFileName.push(newFile)
          this.vedioFile.push(file)
        }else{
          this.resFileName.push(newFile)
          this.attachFiles.push(file)
        }
      });
      this.conversionForm = {
        cname: data.cname,
        content: data.content,
        appdate: data.appdate,
        patentnum: data.patentnum,
        applicant: data.applicant
      };

      this.conversionVisible = true;

    },
    //删除服务
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/conversion/DeletConversion",
          method: "delete",
          data: {
            cid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
.fullscreen .addbutton {
  display: none;
}

::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip {
  display: none !important;
}
.editor-container {
  display: flex;
  flex-direction: column;
  /*border: 1px solid #ccc;*/
  width: 80%;
}
</style>